<template>
<div class="about-view">
  <div class="wrapper">
    <div class="banner-about">
      <div class="title">"If the human brain were so simple that we could understand it, we would be so simple that we couldn’t."</div>
      <div class="name">- Emerson M. Pugh</div>
    </div>
    <div class="about">
      <h1>About Us</h1>
      <div class="about-content">
        <p>MyNewsScan is a news aggregator project, coordinated by Dr. Gedi Luksys and Dr. Robin Hill from the University of Edinburgh and implemented by UoE students/graduates Ahsun Tariq, Yuqi Xiao, Jiahe Lyu, Xinyue Huang, Jiawei Tan, Zhewen Du, Mariana Martinez Juarez, Yiyun Zhu, Jiachen Cai, Ziyi Zhang, Jingxian Zhao, and Kim Ngan Linda Nguyen. </p>
        <p>Its main purpose is to provide useful, diverse and high quality news selection of various topics to international readers. We also collect readers' feedback about the articles to investigate the role of schemas on learning, decision making, and more generally on how factors such as perceived news accuracy, familiarity, readability, usefulness and changing one's view affect their performance and popularity.</p>
        <p>MyNewsScan is not a commercial entity.</p>
      </div>
      <div class="about-line"></div>
      <h1>Moderator Level</h1>
      <div class="about-content">
        <p>We are dedicating to set up a group from users, inviting those who are willing to build our community a better place to moderate the posts and manage the websites.</p>
        <p>In details, we tagged every single user, from regular user to <span style="color: blue; font-weight: bolder;">"super"</span> administrator.</p>
        <p><el-tag type="success">Regular</el-tag> or Level 0: <span style="font-style: italic;">regular user, no any management authority</span></p>
        <p><el-tag >Moderator</el-tag> or Level 1: <span style="font-style: italic;">normal moderator, only have access to moderate contents</span></p>
        <p><el-tag type="warning">Admin</el-tag> or Level 2: <span style="font-style: italic;">administrator, have full access to manage contents, including deletion</span></p>
        <p><el-tag type="danger">Super</el-tag> or Level 3: <span style="font-style: italic;">super administrator with higher authority, have right to assign user to be an administrators</span></p>
      </div>
      <div class="about-line"></div>
      <h1>Contact Us</h1>
      <div class="about-content">
        For any inquiries, suggestions or complaints, please email <a href="mailto:gedi.luksys@ed.ac.uk">gedi.luksys@ed.ac.uk</a><br>
        This project is currently under development, so we very much welcome your input.
      </div>
      
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "About"
}
</script>

<style scoped>
.banner-about{
  display: flex;
  width: 1240px;
  height: 180px;
  /*padding: 0 11%;*/
  font-size: 28px;
  color: #14279B;
  margin: 0 auto;
  line-height: 1.5;
  font-family: "Poppins-SemiBold";
  /*border: 1px solid;*/
  border-radius: 0 0 80px 80px;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  background:url('../assets/images/bg-banner-about.jpg') no-repeat center top;
}
.banner-about .title{
  text-align: center;
  width: 1000px;
  margin: 0 auto;
}
.banner-about .name{
  text-align: right;
  width: 1000px;
  margin: 0 auto;
}
.about{
  display: block;
  width: 1085px;
  margin: 0 auto;
  padding: 30px 0;
  text-align: center;
}
.about h1{
  margin-top: 10px;
  margin-bottom: 30px;
  font-size: 32px;
  font-family: "Poppins-Bold";
  color: #14279B;
}
.about-content{
  font-size: 14px;
  color: #000000;
  line-height: 1.8;
  padding-bottom: 30px;
}
.about-content a{
  color: #4A88F8;
}
.about-content a:hover{
  text-decoration: underline;
}
.about-content .imgs img{
  display: block;
  margin: 0 auto;
}
.about-line{
  display: block;
  height: 1px;
  width: 100%;
  margin-bottom: 35px;
  background-color: #d9d9d9;
}
</style>